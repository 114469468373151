/* You can add global styles to this file, and also import other style files */

#mat-dialog-0{
    padding: 0;
}

html, body { height: 100%; }
body {
    margin: 0;
    font-family: "Segoe UI", Roboto, sans-serif;
}

mat-sidenav-container .mat-list-base .mat-list-item .mat-list-avatar, .mat-list-base .mat-list-option .mat-list-avatar {
    width: auto;
    height: auto;
}

@import '../node_modules/@angular/material/theming';

@include mat-core();


$my-primary: (
    50 : #e0f1e0,
    100 : #b3dcb3,
    200 : #cef5a3,
    300 : #bceb86,
    400 : #b0e078,
    500 : #9ccc65,
    600 : #88b358,
    700 : #769b4d,
    800 : #668542,
    900 : #465f2a,
    A100 : #8bff8b,
    A200 : #58ff58,
    A400 : #25ff25,
    A700 : #0cff0c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-accent: (
    50 : #f9ece4,
    100 : #efcebc,
    200 : #e5ae8f,
    300 : #da8e62,
    400 : #d27540,
    500 : #ca5d1e,
    600 : #c5551a,
    700 : #bd4b16,
    800 : #b74112,
    900 : #ab300a,
    A100 : #ffdfd7,
    A200 : #ffb5a4,
    A400 : #ff8c71,
    A700 : #ff7858,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

//the first two are the custom palettes we declared above
$my-theme-primary: mat-palette($my-primary);
$my-theme-accent: mat-palette($my-accent);
//the third, for the warn color, is the predefined material red palette
$my-theme-warn: mat-palette($mat-red);

$my-theme: mat-light-theme($my-theme-primary, $my-theme-accent, $my-theme-warn);

@include angular-material-theme($my-theme);
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (max-width: 599px) {
    .mat-toolbar-row, .mat-toolbar-single-row {
        height: 64px !important;
    }
}
@media only screen and (max-width: 630px) {
    mat-form-field {
        margin: 10px;
        width: 70% !important;
    }    
    .full-width-dialog .mat-dialog-container {
        width: 90% !important;
      }
  }

b.requi {
    color: rgb(255, 106, 106);
}
//Cancela el padding forzado del dialog
.mat-dialog-container {
    padding: 0px !important;
}

.tooltip-list {
    white-space: pre;
}

.tooltip-content {
    word-break: break-word;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }